import * as React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Document from "../components/svgs/Document/Document";
import { wrap, link, svg, text } from "./Index.module.scss";
import AnchorSvg from "../components/svgs/Anchor/Anchor";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Card from "@material-ui/core/Card";
import useLang from "../contexts/Language/LanguageContext";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import useIsMobile from "../utils/is-mobile/isMobile";
import Footer from "../components/footer/Footer";
import { format } from "date-fns";

const IndexPage = () => {
  const isMobile = useIsMobile();
  const lang = useLang();
  const dataP = useStaticQuery(graphql`
    query {
      allContentfulProject(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            titleen
            slug
            isNews
            rootproject
            publishedDate
            id
            description {
              raw
              references {
                __typename
                ... on ContentfulAsset {
                  contentful_id
                  title
                  gatsbyImageData
                  file {
                    url
                    contentType
                  }
                }
              }
            }
            descriptionen {
              raw
              references {
                __typename
                ... on ContentfulAsset {
                  contentful_id
                  title
                  gatsbyImageData
                  file {
                    url
                    contentType
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <>
            {node.data.target.gatsbyImageData ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GatsbyImage
                  style={{ width: "100%", maxHeight: "280px" }}
                  image={node.data.target.gatsbyImageData}
                  alt={node.data.target.title}
                />
              </div>
            ) : null}
          </>
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        return null;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return null;
      },
    },
  };
  return (
    <>
      <div
        style={{
          display: isMobile ? "block" : "flex",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
          marginBottom: "125px",
          height: "calc(100% - 120px)",
        }}
      >
        <div>
          <h1>{lang === "mne" ? "Naši projekti" : "Our projects"}</h1>
        </div>
        <Carousel
          autoPlay
          interval={7000}
          infiniteLoop
          statusFormatter={(current, total) => `${current} / ${total}`}
        >
          {dataP.allContentfulProject.edges
            .filter((e) => !e.node.isNews && e.node.rootproject)
            .map((edge) => (
              <div key={edge.node.id}>
                <Card
                  style={{
                    marginBottom: "20px",
                    padding: "20px",
                    width: isMobile ? "100%" : "600px",
                    height: isMobile ? "300px" : "400px",
                  }}
                >
                  <Link to={"/projects/" + edge.node.slug}>
                    <h2 style={{ color: "black", marginBottom: "0px" }}>
                      {lang === "mne"
                        ? edge.node.title
                        : edge.node.titleen
                        ? edge.node.titleen
                        : edge.node.title}
                    </h2>
                  </Link>
                  <p
                    style={{
                      fontSize: "10px",
                      color: "gray",
                      marginTop: "2px",
                    }}
                  >
                    {format(new Date(edge.node.publishedDate), "dd.MM.yyyy")}
                  </p>
                  {renderRichText(
                    lang === "mne"
                      ? edge.node.description
                      : edge.node.descriptionen
                      ? edge.node.descriptionen
                      : edge.node.description,
                    options
                  )}
                  {/*<div style={{marginTop: "auto"}}>
                <Link to={"/projects/" + edge.node.slug}>
                    <ExitToAppIcon style={{color: "black", width: "40px", height: "40px", display: "block", marginLeft: "auto"}} />
                </Link>
            </div>*/}
                </Card>
              </div>
            ))}
        </Carousel>
        <div>
          <h2 style={{ fontSize: "1.5rem" }}>
            {lang === "mne" ? "Novosti" : "News"}
          </h2>
        </div>
        <Carousel
          autoPlay
          interval={7000}
          infiniteLoop
          statusFormatter={(current, total) => `${current} / ${total}`}
        >
          {dataP.allContentfulProject.edges
            .filter((e) => e.node.isNews)
            .map((edge) => (
              <div key={edge.node.id}>
                <Card
                  style={{
                    marginBottom: "20px",
                    padding: "20px",
                    width: isMobile ? "100%" : "600px",
                    height: isMobile ? "300px" : "400px",
                  }}
                >
                  <Link to={"/news/" + edge.node.slug}>
                    <h2 style={{ color: "black", marginBottom: "0px" }}>
                      {lang === "mne"
                        ? edge.node.title
                        : edge.node.titleen
                        ? edge.node.titleen
                        : edge.node.title}
                    </h2>
                  </Link>
                  <p
                    style={{
                      fontSize: "10px",
                      color: "gray",
                      marginTop: "2px",
                    }}
                  >
                    {format(new Date(edge.node.publishedDate), "dd.MM.yyyy")}
                  </p>
                  {renderRichText(
                    lang === "mne"
                      ? edge.node.description
                      : edge.node.descriptionen
                      ? edge.node.descriptionen
                      : edge.node.description,
                    options
                  )}
                  {/*<div style={{marginTop: "auto"}}>
                <Link to={"/projects/" + edge.node.slug}>
                    <ExitToAppIcon style={{color: "black", width: "40px", height: "40px", display: "block", marginLeft: "auto"}} />
                </Link>
            </div>*/}
                </Card>
              </div>
            ))}
        </Carousel>
      </div>
      <Footer />
    </>
  );
};

export default IndexPage;
